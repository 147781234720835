<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :metadata="metadata"
      :details="details"
      translatePrefix="application.pages"
    />
  </div>
</template>

<script>
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import service from '../api/departureTerminalService'
import departureTerminalStuffingTerminalService from '../api/departureTerminalStuffingTerminalService'
import departureTerminalFreeTimeService from '../api/departureTerminalFreeTimeService'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      details: {}
    }
  },
  methods: {
    async getDepartureTerminalStuffingTerminalMeta() {
      const departureTerminalStuffingTerminalMeta =
        await departureTerminalStuffingTerminalService.getMetaWithValidation()
      helpers.processMetaDetails(
        departureTerminalStuffingTerminalMeta,
        'departureTerminalStuffingTerminal',
        'departureTerminalId',
        'departureTerminal',
        departureTerminalStuffingTerminalService
      )
      return departureTerminalStuffingTerminalMeta
    },
    async getDepartureTerminalFreeTimeMeta() {
      const departureTerminalFreeTimeMeta =
        await departureTerminalFreeTimeService.getMeta()
      helpers.processMetaDetails(
        departureTerminalFreeTimeMeta,
        'departureTerminalFreeTime',
        'departureTerminalId',
        'departureTerminal',
        departureTerminalFreeTimeService
      )
      return departureTerminalFreeTimeMeta
    },
    async getMetaDetails() {
      return await Promise.all([
        this.getDepartureTerminalStuffingTerminalMeta(),
        this.getDepartureTerminalFreeTimeMeta()
      ])
    },
    async getMeta() {
      let [meta, metaDetails] = await Promise.all([
        service.getMeta(),
        this.getMetaDetails()
      ])
      meta.details = metaDetails
      return meta
    }
  },
  created() {
    this.getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta, 'application.pages')
      this.metadata = meta
    })
  }
}
</script>
