import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const baseUrl = '/api/departureTerminalFreeTime'

const service = createCrudService(baseUrl, api, { importLogUrl: '/importLog' })

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.departureTerminal.options = ctx.departureTerminalOptions
}

export default service
